<template>
    <v-dialog
        v-model="value"
        :max-width="maxWidth"
        :persistent="persistent"
        @click:outside.prevent.stop="$emit('input', false)"
        @keydown.esc="$emit('input', false)"
    >
        <v-card style="border-radius: 12px; border: none;">
            <v-toolbar
                v-if="title"
                dark
                :color="$const.color.primary"
            >
                <v-toolbar-title class="fs-18 fw-500" style="white-space: normal;">{{ title }}</v-toolbar-title>
            </v-toolbar>
            <v-card-text class="pa-4">
                <slot />
            </v-card-text>
            <v-card-actions class="pa-4">
                <a
                    v-if="closeButtonVisible"
                    class="color-primary fs-14 d-flex align-center"
                    @click.prevent.stop="$emit('input', false)"
                >
                    <v-icon :color="$const.color.primary" class="mr-1">
                        mdi-chevron-left
                    </v-icon>
                    Закрыть
                </a>
                <slot name="buttons" />
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    props: {
        value: { type: Boolean, default: false },
        persistent: { type: Boolean, default: false },
        title: { type: String, default: '' },
        maxWidth: { type: String, default: '600px' },
        hideCloseBtn:{ type: Boolean, default: false }
    },
    computed: {
        closeButtonVisible () {
            return !this.hideCloseBtn
        }
    }
}
</script>