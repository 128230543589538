<template>
    <custom-dialog
        :value="value"
        persistent
        max-width="500px"
        :title="title"
        @input="(value) => $emit('change', value)"
    >
        <v-text-field
            v-model="confirmationPassword"
            type="password"
            placeholder="Введите пароль"
            hide-details
            :color="$const.color.primary"
        />
        <error-alert
            v-if="!_.isNil(confirmationError)"
            dense
            :message="confirmationError"
            class="mt-4 mb-0"
        />
            <template #buttons>
                <v-spacer />
                <v-btn
                    :disabled="!confirmationPassword"
                    :loading="loading"
                    outlined
                    height="40px"
                    class="button-stylized"
                    @click="actionHandler"
                >Подтвердить</v-btn>
            </template>
    </custom-dialog>
</template>

<script>
import CustomDialog from './CustomDialog.vue'
import ErrorAlert from './ErrorAlert.vue'

export default {
    components: { CustomDialog, ErrorAlert },
    model: {
        prop: 'value',
        event: 'change'
    },
    props: {
        value: { type: Boolean, default: false },
        title: { type: String, default: 'Для подтверждения действия введите пароль от текущей учетной записи' },
        action: { type: Function, required: true }
    },
    data () {
        return {
            loading: false,
            confirmationPassword: '',
            confirmationError: null
        }
    },
    methods: {
        async actionHandler () {
            try {
                this.confirmationError = null

                this.loading = true
                
                const loginHandler = { login: this.$store.state.user.profile.login, password: this.confirmationPassword }
                const { success: s1, data: userData, error: e1 } = await this.$store.dispatch('user/login', loginHandler)
                        
                if (!s1)
                    throw new Error(e1)
                
                this.$store.dispatch('user/setSession', userData)

                await this.action()

                this.$emit('change', false)
            } catch (e) {
                console.error(e)
                this.confirmationError = e?.message
            } finally {
                this.loading = false
            }
        }
    }
}

</script>