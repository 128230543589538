var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('grid-view',{attrs:{"title":"Классы","filter":"","create":"","create-title":"Создать класс","url-create":"/group/create","url-update":"/group/update/:id","store-module":"group","headers":[
        // { text: 'ID', value: 'id', width: 1 },
        // { text: '', value: 'edit', width: 1, sortable: false },
        // { text: 'Активность', value: 'active', width: 1, sortable: false },
        { text: 'Название', value: 'name' },
        { text: 'Кол-во учеников', value: 'student_id', width: 1, sortable: false },
        { text: 'Кол-во педагогов', value: 'teacher_id', width: 1, sortable: false },
        { text: '', value: 'edit', width: 1, sortable: false },
        { text: '', value: 'remove', width: 1, sortable: false }
    ],"format":_vm.formatItems},scopedSlots:_vm._u([{key:"filter",fn:function(ref){
    var filter = ref.filter;
return [_c('v-row',[_c('v-col',{attrs:{"lg":"6","sm":"12"}},[_c('v-select',{attrs:{"items":[{
                    text: 'Все',
                    value: null
                  },{
                    text: 'Нет',
                    value: false
                  },{
                    text: 'Да',
                    value: true
                  }],"label":"Активность","color":_vm.$const.color.primary},model:{value:(filter.active),callback:function ($$v) {_vm.$set(filter, "active", $$v)},expression:"filter.active"}})],1),_c('v-col',{attrs:{"lg":"6","sm":"12"}},[_c('v-text-field',{attrs:{"label":"Название","type":"text","placeholder":" ","color":_vm.$const.color.primary},model:{value:(filter.name),callback:function ($$v) {_vm.$set(filter, "name", $$v)},expression:"filter.name"}})],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }