<template>
    <custom-dialog
        v-model="value"
        title="Фильтр"
    >
        <v-container>
            <slot :model="model" />
        </v-container>

        <template v-slot:buttons>

            <v-spacer />

            <v-btn
                outlined
                height="40px"
                class="button-stylized"
                @click.prevent.stop="reset"
            >
                Сбросить
            </v-btn>
                
            <v-btn
                outlined
                height="40px"
                class="button-stylized-blue"
                @click.prevent.stop="apply"
            >
                Применить
            </v-btn>

        </template>
    </custom-dialog>
</template>

<script>
import CustomDialog from '@/components/template/CustomDialog.vue'

export default {
    name: 'GridView',
    components: { CustomDialog },
    props: {
        value: Boolean,
        storeModule: String,
        extraFilters: { type: Object, default: () => ({}) }
    },
    data() {
        return {
            loading: true,
            model: {}
        }
    },
    watch: {
        '$route.query': {
            handler () {
                if (!Object.keys(this.extraFilters).length) { return false; }
                this.fetch();
            },
            deep: true
        },
    },
    methods: {
        fetch() {
            const filter = _.get(this, `$store.state.${this.storeModule}.filter`, {});
            _.assign(
                this.model,
                _.cloneDeep(filter),
                this.extraFilters
            );

            // Fix values from objects to primitive values
            Object.keys(this.model)
                .forEach((key) => {
                    const field = this.model[key]

                    if (field !== null && typeof field === 'object' && !!field.like) {

                        this.model[key] = field.like

                    }
                })
        },
        async apply() {
            this.close();
            this.loading = true;
            const model = _.cloneDeep(this.model)
            
            for (const key in model) {
                // Skip values with empty strings
                if (model[key] === "") { model[key] = null }
                
                if (model[key] && this.$store.state[this.storeModule].filterRules[key])
                    model[key] = this.$store.state[this.storeModule].filterRules[key](model[key])
            }
            
            await this.$store.dispatch(`${this.storeModule}/filter`, model);
            this.$emit('filter', 1);

            this.loading = false;
        },
        async reset() {
            this.loading = true;

            await this.$store.dispatch(`${this.storeModule}/reset`);
            this.fetch();
            this.apply();
        },
        close() {
            return this.$emit('close', 1);
        }
    },
    created() {
        this.fetch();
    }
}
</script>
<style lang="scss">
</style>